import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useGetUsePoints, useMetrics, useWrappedRouter } from '../../hooks';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import { InfoPopup } from '@dx-ui/osc-info-popup';
import { useIsClient } from 'usehooks-ts';
import { DisplayPoints } from './display-points-mvt/display-points';
import { useState } from 'react';
import { useCurrencyConversion } from '@dx-ui/osc-currency-converter';

export type UsePointsProps = {
  isInvalidOrUnavailableOffer?: boolean;
  onChange?: () => void;
};

export const UsePoints = ({ isInvalidOrUnavailableOffer, onChange }: UsePointsProps) => {
  const { router } = useWrappedRouter();
  const { t } = useTranslation('use-points');
  const { shouldUsePoints, selectedCurrency } = useAppState();
  const dispatch = useAppDispatch();
  const metrics = useMetrics();
  const isClient = useIsClient();
  const [a11yText, setA11yText] = useState('');
  const { currenciesMap } = useCurrencyConversion();
  const selecteCurrencyDesc = currenciesMap?.get(selectedCurrency || 'USD')?.description;

  const {
    isDisabled: isPointsDisabled,
    isChecked: shouldUsePointsChecked,
    infoPopupMessage,
  } = useGetUsePoints(isInvalidOrUnavailableOffer);

  const handleUsePointsChange = () => {
    const a11yMessage = !shouldUsePointsChecked
      ? t('priceChangeToPoints')
      : t('rateChangedToCurrency', { currencyName: selecteCurrencyDesc });
    setA11yText(a11yMessage);
    dispatch({ type: 'SET_USE_POINTS', payload: !shouldUsePoints });
    void metrics.trackUsePointsChecked();
    if (onChange) onChange();
    setTimeout(() => setA11yText(''), 1000);
  };

  return isClient && router.isReady ? (
    <div className="ml-2 mr-0 flex-col pt-5 md:mx-2 md:px-2 rtl:mb-2">
      <div className="flex">
        <label className="label label-inline-reverse rtl:label-inline-reverse text-nowrap">
          <span
            className={cx('text-sm font-semibold lg:text-lg rtl:pl-2 rtl:pr-1', {
              'text-text-disabled': isPointsDisabled,
            })}
          >
            {t('usePoints')}
          </span>
          <input
            checked={shouldUsePointsChecked}
            className="form-checkbox"
            data-testid="searchByUsePoints"
            disabled={isPointsDisabled}
            onChange={handleUsePointsChange}
            type="checkbox"
          />
          {a11yText ? <span className="sr-only">{a11yText}</span> : null}
        </label>
        {infoPopupMessage && (
          <span className="m-1 flex">
            <InfoPopup testId="dayUseRateDisclaimer" label={t('pointsInfo')}>
              <span className="flex w-40">{t(infoPopupMessage)}</span>
            </InfoPopup>
          </span>
        )}
      </div>
      <DisplayPoints />
    </div>
  ) : null;
};

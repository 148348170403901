import { useQuery } from '@tanstack/react-query';
import type { THsoHotel } from './utils/get-hashed-hotel-summary-options-data-from-cache';
import { logError } from '@dx-ui/framework-logger';

const fetchHotelSummaryOptions = async ({
  queryKey,
}: {
  queryKey: [string];
}): Promise<THsoHotel> => {
  const [url] = queryKey;

  const response = await fetch(url);

  if (!response.ok) {
    logError(
      'Shop HSO blob fetch',
      `${response.status}: ${response.statusText}`,
      'failed to fetch blob'
    );
    throw new Error(`Error ${response.status}: ${response.statusText}`);
  }

  return response.json();
};

export const useBlob = ({
  enabled,
  hotelSummaryExtractUrl,
}: {
  enabled: boolean;
  hotelSummaryExtractUrl: string;
}) => {
  const { isFetching, status, data } = useQuery({
    queryKey: [hotelSummaryExtractUrl],
    queryFn: fetchHotelSummaryOptions,
    enabled,
  });

  return {
    data,
    isFetching,
    isInitialRender: status === 'success' || status === 'error',
    status,
  };
};

import { env } from '../../constants';
import { useWrappedRouter } from '../../hooks';
import { MapBase } from './map.base';
import MapProvider from './map.provider';

export const Map = ({
  handleBoundsChange,
  hasUserPanned,
  hasUserZoomed,
  initialBounds,
  children,
}: {
  handleBoundsChange: (newBounds: google.maps.LatLngBoundsLiteral) => void;
  hasUserPanned: React.MutableRefObject<boolean>;
  hasUserZoomed: React.MutableRefObject<boolean>;
  initialBounds: google.maps.LatLngBoundsLiteral;
  children: React.ReactNode;
}) => {
  const { safeQueryParams: queryParameters } = useWrappedRouter();

  return (
    <MapProvider apiKey={env.MAP_API_KEY}>
      <MapBase
        initialBounds={initialBounds}
        onBoundsChange={(bounds) => handleBoundsChange(bounds)}
        onPan={() => {
          // need to know if user interacted with the map
          hasUserPanned.current = true;
        }}
        onZoom={() => (hasUserZoomed.current = true)}
        showInitialBounds={queryParameters?.showBbox}
        showQuadNodeBounds={queryParameters?.isDebugQuadTrees as boolean}
      >
        {children}
      </MapBase>
    </MapProvider>
  );
};

import type { Maybe, HotelQuadrantBounds, HotelQuadrant } from '@dx-ui/queries-dx-shop-search-ui';

type NodeFilterCountry = {
  countryCode: string;
  stateCode?: string | null;
};

type NodeFilter = {
  brandCode?: string | null;
  countries?: NodeFilterCountry[] | null;
};

export type GoogleCoordinate = {
  lat: number;
  lng: number;
};

/**
 * Determines if two rectangular bounds intercept. Basically this method confirms that there is no overlap between rectangles and then returns the result logically NOT'ed.
 *
 * @param bounds1
 * @param bounds2
 */
function isOverlappingBounds(
  bounds1?: Maybe<HotelQuadrantBounds>,
  bounds2?: Maybe<HotelQuadrantBounds>
) {
  return (
    typeof bounds1?.northeast?.latitude !== 'undefined' &&
    typeof bounds2?.southwest?.latitude !== 'undefined' &&
    typeof bounds1?.southwest?.latitude !== 'undefined' &&
    typeof bounds2?.northeast?.latitude !== 'undefined' &&
    !(
      bounds1?.northeast?.latitude < bounds2?.southwest?.latitude ||
      bounds1?.southwest?.latitude > bounds2?.northeast?.latitude ||
      bounds1?.southwest?.longitude > bounds2?.northeast?.longitude ||
      bounds1?.northeast?.longitude < bounds2?.southwest?.longitude
    )
  );
}

/**
 * Determines if the quad node matches the filter criteria.
 *
 * @param {HotelQuadrant} validNode
 * @param {NodeFilter} filter
 */
function isMatchFilter(validNode: HotelQuadrant, filter?: NodeFilter) {
  let brandCodeMatch = true;
  let stateOrCountryMatch = true;
  if (filter) {
    // Filter by brand codes if provided
    if (filter?.brandCode) {
      brandCodeMatch = Boolean(validNode?.brands.find((brand) => brand.code === filter?.brandCode));
    }
    // Filter by country/state if provided
    if (brandCodeMatch && filter.countries && validNode.countries) {
      stateOrCountryMatch = filter.countries.some((c) => {
        const nodeCountry = validNode?.countries?.find(
          (nodeCountry) => nodeCountry.code === c.countryCode
        );
        return !!(nodeCountry && (!c.stateCode || nodeCountry?.states?.includes(c.stateCode)));
      });
    }
  }
  return brandCodeMatch && stateOrCountryMatch;
}

/**
 * Determines the list of quad tree nodes within the bounds provided. Able to also further filter nodes by a list of brands, states, or countries.
 *
 * @param {HotelQuadrant[]} validNodes
 * @param {Maybe<HotelQuadrantBounds>} bounds
 * @param {NodeFilter} filter
 */
const getBoundNodes = (
  validNodes: HotelQuadrant[],
  bounds: Maybe<HotelQuadrantBounds>,
  filter?: NodeFilter
): HotelQuadrant[] => {
  //if we dont have any nodes or a value for bounds is missing return an empty arr
  if (validNodes.length === 0 || !bounds?.northeast || !bounds.southwest) return [];

  // If the bounding box crosses the international time line, create a bounding box for each side of the time line,
  // and get the quad nodes for each.
  const { southwest, northeast } = bounds || {};

  if (southwest?.longitude && northeast?.longitude && southwest?.longitude > northeast?.longitude) {
    return [
      ...getBoundNodes(
        validNodes,
        { southwest, northeast: { ...northeast, longitude: 180 } },
        filter
      ),
      ...getBoundNodes(
        validNodes,
        { southwest: { ...southwest, longitude: -180 }, northeast },
        filter
      ),
    ];
  }

  // Filter quad nodes based on whether it overlaps with the provided bounding box and matches the filter criteria.
  return validNodes
    .filter((validNode) => isOverlappingBounds(validNode?.bounds, bounds))
    .filter((validNode) => isMatchFilter(validNode, filter));
};

export default getBoundNodes;

import { type LoginResponse, useAuth } from '@dx-ui/framework-auth-provider';
import { sendReward } from '@dx-ui/framework-conductrics';
import { Dialog } from '@dx-ui/osc-dialog-v2';
import { LoginIFrame } from '@dx-ui/osc-login';
import { Trans, useTranslation } from 'next-i18next';
import { useCallback, useState } from 'react';
import { useIsClient } from 'usehooks-ts';
import { GOALS, env } from '../../../constants';
import { useWrappedRouter } from '../../../hooks';
import { generateSigninUrl } from '../../../utils';
import { InfoMessageBanner } from '../../info-message-banner/info-message-banner';

export const LogInBanner = () => {
  const isClient = useIsClient();
  const { t } = useTranslation('list-view');
  const { router } = useWrappedRouter();
  const { login, isAuthenticated } = useAuth();
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

  const closeLoginModal = () => setShowLoginModal(false);
  const openLoginModal = () => {
    setShowLoginModal(true);
    sendReward(GOALS.shopLoginClick);
  };

  const lang = router.locale as string;
  const forgetInfoUrl = env.PARTNER_FORGET_INFO_LINK.replace('/__LANG__/', `/${lang}/`);
  const options = {
    showCreatePassword: false,
    forgetInfoLink: { url: forgetInfoUrl },
    secureInfoLink: { url: env.GLOBAL_PRIVACY_URL },
    signInLink: { url: generateSigninUrl(router.asPath, lang) },
  };

  const onSignInAttempt = useCallback(
    async (resp: LoginResponse) => {
      if (resp?.data) {
        await login(resp);
        window.location.reload();
      }
    },
    [login]
  );
  const handleLogin = async (resp: LoginResponse) => {
    await onSignInAttempt?.(resp);
    if (!resp.error) {
      closeLoginModal();
      sendReward(GOALS.shopHonorsLogin);
    }
  };

  return isClient && !isAuthenticated ? (
    <>
      <InfoMessageBanner
        bannerClassName="bg-tertiary"
        iconClassName="text-bg"
        iconName="star-burst"
      >
        <div className="text-bg pl-2.5 pt-px font-bold">
          <Trans
            ns="list-view"
            i18nKey="loginBanner.getExclusiveRates"
            components={{
              SignInLink: (
                <button onClick={openLoginModal} type="button" className="font-normal underline">
                  signing in
                </button>
              ),
            }}
          />
        </div>
      </InfoMessageBanner>
      <Dialog
        isOpen={showLoginModal}
        onDismiss={closeLoginModal}
        size="md"
        title={t('loginBanner.signIn')}
      >
        <LoginIFrame
          data-testid="loginIframe"
          frameSrc={generateSigninUrl(router?.asPath, lang)}
          options={options}
          onLoginAttempt={handleLogin}
          onClose={closeLoginModal}
          title={t('loginBanner.signIn')}
        />
      </Dialog>
    </>
  ) : null;
};

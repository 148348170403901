import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useMap } from './map.context';
import type { IMapMarker, MapMarkerColor } from './map.marker';
import { MAP_MARKER_COLOR } from './map.marker';
import type { IMapStaticMarker } from './map.static-marker';
import type { ClusterIconStyle, MarkerClustererOptions } from '@googlemaps/markerclustererplus';
import MarkerClusterer from '@googlemaps/markerclustererplus';

let MARKER_Z_INDEX = 1;
/**
 * Dynamically generates the price marker shape using SVG path syntax
 */
const getPriceMarkerPath: (width: number) => string = (width) => {
  const height = 15;
  const radius = 4;
  const tailHeight = 10;
  const tailWidth = 10;
  return `
    M 0 0
    l -${tailWidth / 2} -${tailHeight}
    h -${width / 2 - tailWidth / 2}
    a ${radius} ${radius} 0 0 1 -${radius} -${radius}
    v -${height}
    a ${radius} ${radius} 0 0 1 ${radius} -${radius}
    h ${width}
    a ${radius} ${radius} 0 0 1 ${radius} ${radius}
    v ${height}
    a ${radius} ${radius} 0 0 1 -${radius} ${radius}
    h -${width / 2 - tailWidth / 2}
    z`;
};
/**
 * Generates a marker with text overlayed on top of it
 * @param label - the text to display on the marker
 */
const getPriceMarker = (
  label: string,
  isActive: boolean,
  color?: MapMarkerColor
): google.maps.Symbol => {
  const estimatedAverageCharacterWidth = 8;
  const width = label.length * estimatedAverageCharacterWidth;
  const defaultColor = MAP_MARKER_COLOR['DEFAULT'];
  return {
    path: getPriceMarkerPath(width),
    scale: 1,
    fillColor: isActive
      ? color?.active || defaultColor?.active
      : color?.inactive || defaultColor?.inactive,
    fillOpacity: 1,
    strokeColor: 'rgb(228 228 228)',
    strokeWeight: 1.2,
    labelOrigin: new google.maps.Point(0, -22),
  };
};

type IMapMarkers = {
  children: React.ReactNode;
  clusterOptions?: MarkerClustererOptions;
  /**
   * If using an infoWindow, you must provide this prop in order to have it be closed when there is no active selection
   */
  activeMarker: IMapMarker['id'] | null;
  iconClusterPath?: ClusterIconStyle['url'];
};
type ModifiedMarker = { id?: string } & google.maps.MarkerOptions;

const MapMarkers = ({ children, clusterOptions, activeMarker, iconClusterPath }: IMapMarkers) => {
  const { map, clusterer, setClusterer, infoWindow } = useMap();
  const { current: markers } = useRef<Map<string, ModifiedMarker>>(new Map());
  const [staticMarker, setStaticMarker] = useState<React.ReactNode>(null);

  const markersToDisplay = React.useMemo(
    () =>
      React.Children.map(children, (child) => {
        if (React.isValidElement<IMapMarker>(child)) {
          // this is reducing the time it takes the add/remove marker useEffect to run by just under 1 sec per run
          const { children: _children, ...propsWithoutChildren } = child.props;
          return propsWithoutChildren;
        }
        if (React.isValidElement<IMapStaticMarker>(child)) {
          setStaticMarker(child);
        }
        return null;
      })?.filter(Boolean) as IMapMarker[],
    [children]
  );
  const getMarker = (id?: IMapMarker['id']) =>
    markers.get(id as string) as google.maps.Marker | undefined;
  const addMarker = (m: IMapMarker) => {
    if (map && m) {
      const marker = new google.maps.Marker({
        id: m.id,
        icon: m.label
          ? getPriceMarker(m.label, !!m.isActive, m.color)
          : { url: m.icon, scaledSize: new google.maps.Size(22, 30) },
        label: m.label
          ? { text: m.label, color: '#ffffff', fontWeight: 'bold', fontFamily: 'Hilton Sans' }
          : undefined,
        position: m.position,
        optimized: false,
        visible: true,
        zIndex: MARKER_Z_INDEX++,
        clickable: true,
        ...m.markerOptions,
      } as ModifiedMarker);
      let originalZindex = marker.getZIndex();
      marker.addListener('mouseover', () => {
        originalZindex = marker.getZIndex();
        marker.setZIndex(MARKER_Z_INDEX + 1000);
        if (m.onMouseOver) {
          m.onMouseOver();
        }
      });
      marker.addListener('mouseout', () => {
        if (originalZindex) marker.setZIndex(originalZindex);
        if (m.onMouseOut) {
          m.onMouseOut();
        }
      });
      marker.addListener('click', () => {
        marker.setZIndex(MARKER_Z_INDEX + 1000);
        if (m.onClick) {
          m.onClick();
        }
      });
      markers.set(m.id as string, marker as ModifiedMarker);
      return marker;
    }
  };
  const removeMarker = (marker: google.maps.Marker) => {
    if (map && marker) {
      google.maps.event.clearListeners(marker, 'mouseover');
      markers.delete((marker as ModifiedMarker).id as string);
      return marker;
    }
  };

  useEffect(() => {
    if (map && infoWindow) {
      if (activeMarker) {
        const mapMarker = getMarker(activeMarker);
        if (mapMarker) {
          infoWindow.open({
            anchor: mapMarker as google.maps.MVCObject | google.maps.marker.AdvancedMarkerElement,
            map: map as google.maps.Map | google.maps.StreetViewPanorama,
            shouldFocus: false,
          });
        }
      } else {
        infoWindow.close();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, infoWindow, activeMarker]);

  useEffect(() => {
    const shouldHaveCluster = iconClusterPath || clusterOptions;
    if (map) {
      if (shouldHaveCluster && !clusterer) {
        setClusterer(
          new MarkerClusterer(map, [], {
            averageCenter: true,
            minimumClusterSize: 4,
            maxZoom: 9,
            ignoreHidden: true,
            styles: [
              {
                textSize: 16,
                textColor: '#fff',
                url: iconClusterPath,
                height: 53,
                width: 53,
                anchorText: [16, 0],
              },
            ],
            ...(clusterOptions || {}),
          })
        );
        return;
      }

      // markers to update
      markersToDisplay.forEach((m) => {
        const marker = getMarker(m.id);
        if (marker) {
          // Anything that changes a label will require re-setting both label and icon on existing markers. Also update marker if icon state changes (no price => price marker for example)
          marker.setIcon(
            m.label
              ? getPriceMarker(m.label, !!m.isActive, m.color)
              : { url: m.icon || '', scaledSize: new google.maps.Size(22, 30) }
          );
          if (m.label !== marker.getLabel())
            if (m.label)
              marker.setLabel({
                text: m.label,
                color: '#ffffff',
                fontWeight: 'bold',
                fontFamily: 'Hilton Sans',
              });
            else marker.setLabel(null);
        }
      });
      // markers to add (exclude any marker w/out an id as it doesnt represent a ctyhocn)
      const markersToAdd = markersToDisplay
        .filter(({ id }) => !getMarker(id as string))
        .map((m) => addMarker(m))
        .filter((m) => m !== undefined && !!(m as ModifiedMarker).id) as google.maps.Marker[];
      if (clusterer) {
        clusterer.addMarkers(markersToAdd);
      } else {
        markersToAdd.forEach((m) => {
          m?.setMap(map);
        });
      }
      // markers to remove
      const toRemove = [...markers]
        .filter(([id, _marker]) => !markersToDisplay.find((m) => m.id === id))
        .map(([_id, marker]) => removeMarker(marker as google.maps.Marker))
        .filter((m) => m !== undefined);

      if (clusterer) {
        clusterer.removeMarkers(toRemove);
      } else {
        toRemove.forEach((m) => {
          m.setMap(null);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, iconClusterPath, clusterOptions, clusterer, markersToDisplay]);

  return (
    <>
      {staticMarker}
      {children}
    </>
  );
};

export default MapMarkers;

import { useTranslation } from 'react-i18next';
import { VERTICAL_SPACE_SAVINGS } from '../constants';
import { useGetMVTSelections } from './use-get-mvt-selections';

export const useVerticalSpaceMVT = () => {
  const { t } = useTranslation('filters');
  const {
    isLoaded: isVerticalSpaceMVTLoaded,
    isMVTVariant: {
      b: isVerticalSpaceVar1,
      c: isVerticalSpaceVar2,
      d: isVerticalSpaceVar3,
      e: isVerticalSpaceVar4,
      f: isVerticalSpaceVar5,
      g: isVerticalSpaceVar6,
      h: isVerticalSpaceVar7,
      i: isVerticalSpaceVar8,
    },
  } = useGetMVTSelections({
    agentId: VERTICAL_SPACE_SAVINGS.agentId,
    MVTVariants: ['b', 'c', 'd', 'e', 'f', 'g', 'h', 'i'],
  });
  const filterAndSortLabel = t('filterAndSort');
  const isFilterAndSortInAccordion =
    isVerticalSpaceVar1 || isVerticalSpaceVar5 || isVerticalSpaceVar6;
  const isSuggestedFiltersInHorizontalScroll =
    isVerticalSpaceVar4 || isVerticalSpaceVar6 || isVerticalSpaceVar8;
  const isShorterSuggestedFilters =
    isVerticalSpaceVar3 || isVerticalSpaceVar5 || isVerticalSpaceVar7;
  const isCurrencyAndSortInAllFiltersModal =
    isVerticalSpaceVar2 || isVerticalSpaceVar7 || isVerticalSpaceVar8;

  return isVerticalSpaceMVTLoaded
    ? {
        filterAndSortLabel,
        isFilterAndSortInAccordion,
        isShorterSuggestedFilters,
        isCurrencyAndSortInAllFiltersModal,
        isSuggestedFiltersInHorizontalScroll,
        isVerticalSpaceVar8,
      }
    : {};
};

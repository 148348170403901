import { forwardRef, useMemo, useState } from 'react';

import type { HotelType } from '../../providers/app-provider/app-provider.types';
import { useTranslation } from 'next-i18next';
import type { GoogleCoordinate } from '../../utils';
import { getDistanceFromCenterFmt } from '../../utils';
import HotelCard from './hotel-card';
import HotelCardSkeleton from './hotel-card-skeleton';
import { useMetrics, useWrappedRouter } from '../../hooks';

import type { ShopMultiPropAvailQuery } from '@dx-ui/queries-dx-shop-search-ui';
import { useAppDispatch } from '../../providers/app-provider';
import {
  DEFAULT_HOTEL_CARD_SKELETON_COUNT,
  MAX_PROPERTIES_PER_LIST_VIEW_PAGE,
} from '../../constants';
import { InfoMessageBanner } from '../info-message-banner/info-message-banner';
import { useGetPOIAirportInfoMVT } from './hooks/use-get-poi-airport-info-mvt';

export type IHotelCards = {
  hotels: HotelType[];
  pricing?: Record<string, ShopMultiPropAvailQuery['shopMultiPropAvail'][0]>;
  showImages: boolean;
  isListViewOnly?: boolean;
  isLoadingState?: boolean;
  centerCoordinate: GoogleCoordinate | null;
  cardsClassName?: string;
  initHotelCount?: number;
} & React.HTMLAttributes<HTMLUListElement>;

const HotelCards = forwardRef<HTMLUListElement, IHotelCards>(
  (
    {
      hotels,
      pricing,
      showImages,
      isListViewOnly,
      isLoadingState,
      centerCoordinate,
      cardsClassName,
      initHotelCount,
      ...rest
    },
    forwardedRef
  ) => {
    const { t } = useTranslation(['hotel-card', 'rate-name-cta', 'locations-page']);
    const dispatch = useAppDispatch();
    const metrics = useMetrics();
    const { safeQueryParams: queryParameters, router } = useWrappedRouter();

    //NHCSEARCH-5621
    const [enablePOIAndAirportTest, setEnablePOIAndAirporTest] = useState(false);
    useGetPOIAirportInfoMVT({ enableTest: enablePOIAndAirportTest });

    const handleDetailsClick = (ctyhocn: string, name: string, index: number) => {
      setEnablePOIAndAirporTest(true);
      dispatch({ type: 'SET_PDP_CTYHOCN', payload: ctyhocn });
      metrics.setBrandDetail({
        ctyhocn,
        view: 'List',
        name,
        index: index + 1,
      });
    };

    const skeletonCardCount = useMemo(() => {
      if (initHotelCount && initHotelCount > 0) {
        return initHotelCount && initHotelCount > MAX_PROPERTIES_PER_LIST_VIEW_PAGE
          ? MAX_PROPERTIES_PER_LIST_VIEW_PAGE
          : initHotelCount;
      }
      return DEFAULT_HOTEL_CARD_SKELETON_COUNT;
    }, [initHotelCount]);
    if (isLoadingState) {
      let cnt = 0;
      return (
        <ul ref={forwardedRef} {...rest} className={cardsClassName}>
          {Array.from({ length: skeletonCardCount }, () => (
            <HotelCardSkeleton
              key={cnt++}
              isListViewOnly={isListViewOnly}
              showImages={showImages}
            />
          ))}
        </ul>
      );
    }
    const keyString: string | undefined =
      Object.keys(rest).find((key) => key === 'data-testid') ?? '';

    const firstAvailableProperty = hotels.findIndex((hotel) =>
      router.asPath.includes('/search/')
        ? pricing?.[hotel?.ctyhocn]?.summary?.status?.type === 'AVAILABLE'
        : hotel?.display?.open && hotel?.leadRate?.lowest
    );
    const SoldOutInfoMessageBanner = () => {
      /* show property sold out info banner if specific searched property sold out */
      const hotelPlaceId = queryParameters?.placeId?.includes('dx-hotel')
        ? queryParameters?.placeId?.split('::')[1]?.toUpperCase()
        : null;
      const queryParam = queryParameters?.query?.toUpperCase();
      return (hotelPlaceId && pricing?.[hotelPlaceId]?.statusCode === 1740) ||
        (queryParam && pricing?.[queryParam]?.statusCode === 1740) ? (
        <InfoMessageBanner message={t('selectedHotelSoldOut')} />
      ) : null;
    };
    if (!hotels.length) return null;

    return (
      <>
        <SoldOutInfoMessageBanner />
        <ul
          key={`ulHotelCard-${keyString}`}
          ref={forwardedRef}
          {...rest}
          className={cardsClassName}
        >
          {hotels.map((hotel, _id) => {
            const { name, ctyhocn, localization } = hotel;
            const { distanceFmt } = getDistanceFromCenterFmt(
              t,
              centerCoordinate,
              localization?.coordinate,
              router.locale
            );
            const priceInfo = pricing && pricing[ctyhocn];

            return (
              <HotelCard
                key={`hotel-card-${ctyhocn}`}
                distance={distanceFmt}
                isTALoadingState={isLoadingState}
                isListViewOnly={isListViewOnly}
                onDetailsClick={() => handleDetailsClick(ctyhocn, name || '', _id)}
                showImages={showImages}
                priceInfo={priceInfo}
                hotelInfo={hotel}
                isFirstAvailableProperty={_id === firstAvailableProperty}
              />
            );
          })}
        </ul>
      </>
    );
  }
);

HotelCards.displayName = 'HotelCards';

export default HotelCards;

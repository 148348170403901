import type { QueryParameters } from '../../constants';

import type { ShopMultiPropAvailPointsQuery } from '@dx-ui/queries-dx-shop-search-ui';

export const hasAgeBased = (
  queryParameters?: QueryParameters,
  pricing?: Record<string, ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0]>
): boolean => {
  if (queryParameters)
    return Object.values(pricing || {}).filter((price) => price?.ageBasedPricing).length > 0;
  return false;
};

export const hasChildAges = (queryParameters?: QueryParameters): boolean =>
  (queryParameters &&
    queryParameters.rooms
      .filter(({ children, childAges }) => {
        if (children > 0) {
          return (
            childAges &&
            typeof childAges !== 'number' &&
            childAges.filter(({ age }) => typeof age !== 'undefined').length !== children
          );
        }
        return false;
      })
      .filter(Boolean).length > 0) ||
  false;

export const hasTMTPRatesSelected = (queryParameters: QueryParameters): boolean =>
  queryParameters &&
  (queryParameters.employeeRate ||
    queryParameters.friendsAndFamilyRate ||
    queryParameters.ownerVIPRate);

import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import { sendReward } from '@dx-ui/framework-conductrics';
import { COMPARE_ONBOARDING, GOALS } from '../../constants';
import { useLocalStorage, useMediaQuery } from 'usehooks-ts';
import { DESKTOP_COMPARE_LIMIT, MOBILE_COMPARE_LIMIT } from './compare-hotel-utils';
import { FeatureToolTip } from './feature-tool-tip';
import { useGetMVTSelections } from '../../hooks';
import { useRef } from 'react';

type CompareCheckboxProps = {
  ctyhocn?: string;
  onChange?: () => void;
  isFirstCheckbox?: boolean;
};

export const CompareHotelsCheckbox = ({
  onChange,
  ctyhocn: selectedCtyhocn,
  isFirstCheckbox,
}: CompareCheckboxProps) => {
  const { t } = useTranslation('compare-hotels');
  const isSubDesktop = useMediaQuery('(max-width: 1023px)');
  const compareCheckboxRef = useRef<HTMLInputElement>(null);

  const { hotelsToCompare, compareHotelsToggle: showCompareCheckBox } = useAppState();
  const dispatch = useAppDispatch();

  //NHCSEARCH-5449 | Compare Hotels Onboarding MVT
  const {
    isLoaded: isOnboardingMVTLoaded,
    isMVTVariant: { c: isOnboardingVarC },
  } = useGetMVTSelections({ agentId: COMPARE_ONBOARDING.agentId, MVTVariants: ['c'] });
  const [isOnboarded, setIsOnboarded] = useLocalStorage('compare-hotels-onboarded', false);
  const handleToolTipClose = () => {
    setIsOnboarded(true);
    if (compareCheckboxRef.current) compareCheckboxRef.current.focus();
  };

  const handleCompareHotelChange = () => {
    if (onChange) onChange();

    if (selectedCtyhocn) {
      let selectedHotels = [...hotelsToCompare];
      if (hotelsToCompare.includes(selectedCtyhocn))
        selectedHotels = hotelsToCompare?.filter((hotel) => hotel !== selectedCtyhocn);
      else selectedHotels.push(selectedCtyhocn);
      dispatch({ type: 'SET_HOTELS_TO_COMPARE', payload: selectedHotels });
      if (selectedHotels.length) sendReward(GOALS.compareHotelsCheckboxSelected);
    }
    if (!isOnboarded && isOnboardingVarC) setIsOnboarded(true);
  };
  const isCompareCBChecked = selectedCtyhocn ? !!hotelsToCompare?.includes(selectedCtyhocn) : false;
  //Disable checkbox when hotels selected is 2 for mobile and  5 selected for other large device
  const isCompareCBDisabled =
    !isCompareCBChecked &&
    (isSubDesktop
      ? hotelsToCompare?.length >= MOBILE_COMPARE_LIMIT
      : hotelsToCompare?.length === DESKTOP_COMPARE_LIMIT);

  return showCompareCheckBox ? (
    <div className="relative mr-0 pt-1.5 rtl:mb-2">
      <div className="flex">
        <label className="label label-inline-reverse rtl:label-inline-reverse">
          <span className={cx('text-base font-semibold rtl:pl-2 rtl:pr-1')}>{t('compare')}</span>
          <input
            className="form-checkbox"
            checked={isCompareCBChecked}
            disabled={isCompareCBDisabled}
            onChange={handleCompareHotelChange}
            type="checkbox"
            ref={compareCheckboxRef}
          />
        </label>
      </div>
      {/* NHCSEARCH-5449 | Compare Hotels Onboarding MVT */}
      <FeatureToolTip
        absolutePosition="-left-5 bottom-9"
        title={t('startComparing')}
        message={t('selectFirstHotel')}
        onDismiss={handleToolTipClose}
        cta={{ label: t('gotIt'), onClick: handleToolTipClose }}
        containerClass="w-[343px] p-3"
        shouldShowFeatureToolTip={
          !!(isOnboardingMVTLoaded && isOnboardingVarC && !isOnboarded && isFirstCheckbox)
        }
      />
    </div>
  ) : null;
};

import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useFilterState } from '../../providers/filter-provider';
import type { HotelAmenityId, Maybe } from '@dx-ui/queries-dx-shop-search-ui';
import type { ActiveFiltersState } from './filter.constants';
import { useMetrics } from '../../hooks';
import { GOALS } from '../../constants';
import { useSuggestedFilters } from '../../hooks/use-suggested-filters';
import Icon, { AmenityIcons } from '@dx-ui/osc-icon';
import { HotelAttributeIds } from './filter.constants';
import { sendReward } from '@dx-ui/framework-conductrics';
import { TaxonomyList } from '@dx-ui/osc-taxonomy-list';
import { useState } from 'react';
import { useVerticalSpaceMVT } from '../../hooks/use-vertical-space-MVT';

export type AmenityItem = {
  id: string;
  name: string;
  hint?: string | null;
  count?: number;
};
type SuggestedFilterButtonsProps = {
  amenities?: AmenityItem[];
  isConductricsLoaded?: boolean;
  isFindingHotels?: boolean;
  onFilterCriteriaChange: (activeFiltersState: ActiveFiltersState) => void;
  visibleHotels: string[];
  matchId?: Maybe<string>;
  saleFilter?: { showSaleFilter: boolean; saleFilterCount: number };
};

type SuggestedFiltersAmenitiesProps = {
  suggestedFilterOrderedList: AmenityItem[];
  onFilterCriteriaChange: (activeFiltersState: ActiveFiltersState) => void;
  showSaleFilter?: boolean;
  suggestedFilterMVTVariant?: Record<string, boolean>;
};

/*
 * SuggestedFilterButtons
 * Displays the Suggested Filter Component
 */
export const SuggestedFilterButtons = ({
  amenities = [],
  isConductricsLoaded,
  isFindingHotels,
  onFilterCriteriaChange,
  visibleHotels,
  matchId,
  saleFilter,
}: SuggestedFilterButtonsProps) => {
  const { isAtleastOneSuggestedFilter, topSuggestedFilters } = useSuggestedFilters({
    visibleHotels,
    amenities,
    matchId,
    saleFilter,
  });
  const suggestedFiltersOrderedList = Object.values(topSuggestedFilters);

  const showSuggestedFilters = isAtleastOneSuggestedFilter || saleFilter?.showSaleFilter;

  const { isSuggestedFiltersInHorizontalScroll } = useVerticalSpaceMVT();

  if (showSuggestedFilters) {
    if (isFindingHotels || !isConductricsLoaded || !amenities.length)
      return <SuggestedFiltersShimmer shouldShowTitle={!isSuggestedFiltersInHorizontalScroll} />;
    else if (isConductricsLoaded && !!visibleHotels.length)
      return (
        <>
          {isSuggestedFiltersInHorizontalScroll ? null : <SuggestedFiltersTitle />}
          <SuggestedFiltersAmenities
            onFilterCriteriaChange={onFilterCriteriaChange}
            suggestedFilterOrderedList={suggestedFiltersOrderedList}
            showSaleFilter={saleFilter?.showSaleFilter}
          />
        </>
      );
  }
  return null;
};

/*
 * SuggestedFiltersShimmer
 * Returns the Suggested Filters Shimmer State
 */
const SuggestedFiltersShimmer = ({ shouldShowTitle }: { shouldShowTitle?: boolean }) => (
  <>
    {shouldShowTitle ? (
      <div className="flex" data-testid="suggested-filters-shimmer">
        <div className="bg-bg-alt mb-4 h-6 w-32 animate-pulse" />
      </div>
    ) : null}
    <div
      className={cx('flex h-32 gap-2 lg:h-16', {
        '-mb-3 pt-3.5': !shouldShowTitle,
      })}
    >
      <div className="bg-bg-alt mr-2 h-10 w-20 animate-pulse rounded-full lg:mr-1 lg:w-36" />
      <div className="bg-bg-alt mr-2 h-10 w-20 animate-pulse rounded-full lg:mr-1 lg:w-36" />
      <div className="bg-bg-alt mr-2 h-10 w-20 animate-pulse rounded-full lg:mr-1 lg:w-36" />
      <div className="bg-bg-alt mr-2 h-10 w-20 animate-pulse rounded-full lg:mr-1 lg:w-36" />
    </div>
  </>
);

/*
 * Returns Suggest Filters Title
 */
const SuggestedFiltersTitle = () => {
  const { t } = useTranslation('filters');
  return (
    <h2 className="label mr-1 flex items-start pb-1 font-semibold tracking-tight rtl:mx-0">
      {t('suggestedFiltersLabel')}
    </h2>
  );
};

const SuggestedFiltersAmenities = ({
  onFilterCriteriaChange,
  suggestedFilterOrderedList,
}: SuggestedFiltersAmenitiesProps) => {
  const { t } = useTranslation('filters');
  const activeFiltersState = useFilterState();

  /* NHCSEARCH-5451 Vertical Space MVT */
  const { isShorterSuggestedFilters, isSuggestedFiltersInHorizontalScroll } = useVerticalSpaceMVT();

  const getTaxonomyListClassName = () => {
    switch (true) {
      case isShorterSuggestedFilters:
        return '-mb-3';
      case isSuggestedFiltersInHorizontalScroll:
        return '-mb-3 pt-3.5';
      default:
        return undefined;
    }
  };
  return (
    <div className="pb-2">
      <TaxonomyList
        labelledBy="suggestedFilters"
        testId="suggested-filters"
        name={t('suggestedFiltersLabel')}
        listItemClassname={getTaxonomyListClassName()}
      >
        {suggestedFilterOrderedList?.map((amenity) => {
          const amenityListCount = amenity?.count;
          const amenitySelected =
            activeFiltersState.amenityFilters.includes(amenity?.id ?? '') ||
            activeFiltersState.attributeFilters.includes(amenity?.id);
          return amenityListCount ? (
            <SuggestedFilterButton
              amenity={amenity}
              amenityListCount={amenityListCount}
              isAmenitySelected={amenitySelected}
              onFilterCriteriaChange={onFilterCriteriaChange}
            />
          ) : null;
        })}
      </TaxonomyList>
      <span aria-hidden="true" className="sr-only" id="suggestedFilters">
        {t('suggestedFiltersLabel')}
      </span>
    </div>
  );
};
function SuggestedFilterButton({
  amenity,
  amenityListCount,
  isAmenitySelected,
  onFilterCriteriaChange,
}: {
  amenity: AmenityItem;
  amenityListCount: number;
  isAmenitySelected: boolean;
  onFilterCriteriaChange: (activeFiltersState: ActiveFiltersState) => void;
}) {
  const [a11yText, setA11yText] = useState<string>('');
  const [selectedAmenity, setSelectedAmenity] = useState('');
  const { t } = useTranslation('filters');
  const activeFiltersState = useFilterState();
  const metrics = useMetrics();

  /* NHCSEARCH-5451 Vertical Space MVT */
  const { isShorterSuggestedFilters } = useVerticalSpaceMVT();

  const resetSuggestedFilter = (amenityId: HotelAmenityId | typeof HotelAttributeIds.sale) => {
    const attributeFilters = [...activeFiltersState.attributeFilters];
    const attributeIndex = attributeFilters.indexOf(amenityId);
    const amenityFilters = [...activeFiltersState.amenityFilters];
    const amenityIndex = amenityFilters.indexOf(amenityId);

    if (amenityId === HotelAttributeIds.sale) {
      if (attributeIndex !== -1) attributeFilters.splice(attributeIndex, 1);
      else attributeFilters.push(amenityId);
    } else {
      if (amenityIndex !== -1) amenityFilters.splice(amenityIndex, 1);
      else amenityFilters.push(amenityId);
    }

    const updatedActiveFiltersState = {
      ...activeFiltersState,
      amenityFilters,
      attributeFilters,
    };

    onFilterCriteriaChange(updatedActiveFiltersState);
  };
  const handleOnClick = async (
    amenityId: HotelAmenityId | typeof HotelAttributeIds.sale,
    amenitySelected: boolean
  ) => {
    setSelectedAmenity(amenityId);
    const suggestedFiltersA11YText = amenitySelected
      ? t('suggestedFiltersRemoved')
      : t('suggestedFiltersApplied');
    setA11yText(suggestedFiltersA11YText);

    resetSuggestedFilter(amenityId);
    sendReward(GOALS.suggestedFilterEngagement);
    await metrics.trackSuggestedFilters(amenityId);

    setTimeout(() => {
      if (amenitySelected) setA11yText('');
    }, 2000);
  };

  return (
    <div className="h-10 px-1" key={amenity.name}>
      <button
        type="button"
        name={`${amenity?.name}  (${amenityListCount})`}
        aria-label={`${amenity?.name} ${amenityListCount} ${
          selectedAmenity === amenity.id ? a11yText : ''
        }`}
        className={cx(
          'btn btn-primary-outline focus:border-primary flex items-center rounded-full',
          {
            'text-bg bg-primary outline-none active:text-text-inverse focus:text-text-inverse hover:text-text-inverse':
              isAmenitySelected,
            'h-[26px] gap-x-1': isShorterSuggestedFilters,
            'gap-x-2': !isShorterSuggestedFilters,
          }
        )}
        onClick={() => handleOnClick(amenity?.id as HotelAmenityId, isAmenitySelected)}
      >
        <span>
          <Icon
            name={AmenityIcons[amenity.id as HotelAmenityId] || amenity.id}
            size={isShorterSuggestedFilters ? 'xs' : 'md'}
          />
        </span>
        <span
          aria-hidden
          className={cx({
            'text-xs font-normal': isShorterSuggestedFilters,
            'text-sm font-bold': !isShorterSuggestedFilters,
          })}
        >
          <span>{`${amenity.name} (${amenityListCount})`}</span>
        </span>
      </button>
      {a11yText && selectedAmenity === amenity.id ? (
        <span aria-live="assertive" className="sr-only -order-1">
          <span role="status">{`${amenity?.name} ${a11yText}`} </span>
        </span>
      ) : null}
    </div>
  );
}

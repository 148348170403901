import { useCallback, useMemo } from 'react';

import type { HotelType } from '../../providers/app-provider/app-provider.types';
import { isAffiliateHotel, useMetrics, useRenovationChip, useWrappedRouter } from '../../hooks';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import type { GoogleCoordinate } from '../../utils';
import {
  getCMAPrice,
  getLengthOfStay,
  isSpecialRatesDeeplinked,
  prefixPublicAssetUriWithAppPrefix,
} from '../../utils';
import { isLocalCurrencySameAsHotelCurrency } from '../hotel-card/utils/hotel-info-utils';
import MapInfoWindow from './map.info-window';
import MapMarker, { MAP_MARKER_COLOR } from './map.marker';
import MapStaticMarker from './map.static-marker';
import MapMarkers from './map.markers';
import type { HotelBadgeTypes } from '../hotel-card/hotel-badges';
import { HotelBadges } from '../hotel-card/hotel-badges';
import { InfoWindow } from '@dx-ui/osc-map';
import { formatAndConvertPrice, useCurrencyConversion } from '@dx-ui/osc-currency-converter';
import useShopMultiPropAvail from '../../hooks/use-shop-multi-prop-avail/use-shop-multi-prop-avail';
import { MAX_PRICE_PROPERTY_LIMIT } from '../../constants';

export const SearchMapMarkers = ({
  centerCoordinate,
  matchId,
  sortedHotels,
}: {
  centerCoordinate: GoogleCoordinate | null;
  matchId?: string | null;
  sortedHotels: HotelType[];
}) => {
  const visibleHotels = sortedHotels
    ?.slice(0, MAX_PRICE_PROPERTY_LIMIT - 1)
    ?.map((hotel) => hotel.ctyhocn);

  const { safeQueryParams: queryParameters, router } = useWrappedRouter();

  const hasSpecialRatesDeepLinked = useMemo(
    () => isSpecialRatesDeeplinked(queryParameters),
    [queryParameters]
  );
  const { hashedData: hashedMPAData, isLoading: isMPADataLoading } = useShopMultiPropAvail({
    ctyhocns: visibleHotels,
    enabled: hasSpecialRatesDeepLinked,
  });

  const {
    activeProperty,
    selectedCurrency,
    selectedCtyhocn,
    shouldUsePoints,
    pageType,
    hasSpecialRate,
  } = useAppState();
  const { currenciesError, currenciesMap } = useCurrencyConversion();
  const dispatch = useAppDispatch();
  const metrics = useMetrics();
  const { isPointsExplorer } = pageType;
  const { renoChipConfig } = useRenovationChip();

  const handleMapMarkerClicked = useCallback(
    (ctyhocn: string, name?: string | null) => {
      dispatch({ type: 'SET_PDP_CTYHOCN', payload: ctyhocn });
      metrics.setBrandDetail({
        ctyhocn,
        view: 'Map',
        name: name || '',
        index: 1,
      });
    },
    [dispatch, metrics]
  );
  const showPointsInMarker = !hasSpecialRate && shouldUsePoints;
  const propertySearch = matchId?.includes('dx-hotel::');
  return (
    <MapMarkers
      activeMarker={activeProperty || selectedCtyhocn}
      iconClusterPath="/modules/assets/svgs/map/cluster/HI.svg"
    >
      {/** Do not render static center marker if specific property searched */}
      <MapStaticMarker
        icon={prefixPublicAssetUriWithAppPrefix('/assets/map-pin-active.png')}
        position={centerCoordinate}
        shouldShowCenterMarker={!propertySearch}
      />
      {!isMPADataLoading &&
        sortedHotels.map(
          ({ address, brandCode, localization, ctyhocn, name, leadRate, amenityIds, display }) => {
            const badges: HotelBadgeTypes[] = [];
            if (hashedMPAData?.[ctyhocn]?.summary?.lowest?.ratePlan?.attributes?.includes('sale'))
              badges.push('sale');
            if (amenityIds.includes('newHotel')) badges.push('new');
            if (renoChipConfig?.includes(ctyhocn)) badges.push('recentlyRenovated');

            const position = {
              lat: localization?.coordinate?.latitude || 0,
              lng: localization?.coordinate?.longitude || 0,
            };
            const hasConfidentialRate =
              hashedMPAData?.[ctyhocn]?.summary?.lowest?.ratePlan?.confidentialRates;
            const currentHotel = hashedMPAData[ctyhocn];
            const isNotBookable =
              hashedMPAData &&
              currentHotel &&
              ((currentHotel.summary?.status?.type === 'NOT_AVAILABLE' &&
                !queryParameters?.datesFlex) ||
                currentHotel.summary?.status?.type === 'NOT_OPEN');

            // do not display prices/points when flexDates enabled or avail status is effectively not bookable. Fallback to price if pts not avail
            const displayPriceInMarker =
              !isNotBookable && !queryParameters?.datesFlex && !hasConfidentialRate;
            const displayPointsInMarker = showPointsInMarker && displayPriceInMarker;
            const { totalRate, totalRateFmt } = getCMAPrice({
              cmaTotalPriceIndicator:
                hashedMPAData?.[ctyhocn]?.summary?.lowest?.cmaTotalPriceIndicator,
              rateAmount: hashedMPAData?.[ctyhocn]?.summary?.lowest?.rateAmount,
              rateAmountFmt: hashedMPAData?.[ctyhocn]?.summary?.lowest?.rateAmountFmt,
              totalRate: hashedMPAData?.[ctyhocn]?.summary?.lowest?.amountAfterTax,
              totalRateFmt: hashedMPAData?.[ctyhocn]?.summary?.lowest?.amountAfterTaxFmt,
              lengthOfStay: getLengthOfStay(
                queryParameters?.departureDate,
                queryParameters?.departureDate
              ),
            });

            const markerPrice = hasConfidentialRate
              ? undefined
              : isLocalCurrencySameAsHotelCurrency(
                  selectedCurrency,
                  localization?.currencyCode || ''
                )
              ? totalRateFmt
              : formatAndConvertPrice({
                  basePrice: !totalRate ? undefined : totalRate,
                  currencyQueryError: !!currenciesError,
                  fromCurrency: currenciesMap?.get('USD'),
                  language: router.locale,
                  toCurrency: currenciesMap.get(selectedCurrency || ''),
                }) || totalRateFmt;

            const markerPointsOrPrice =
              hashedMPAData?.[ctyhocn]?.summary?.hhonors?.dailyRmPointsRateFmt || markerPrice;

            const pointsRangeLabel = leadRate?.hhonors
              ? `${leadRate?.hhonors.min?.dailyRmPointsRateRoundFmt} - ${leadRate?.hhonors.max?.dailyRmPointsRateRoundFmt}`
              : '';
            const markerLabel =
              sortedHotels.length > 150
                ? undefined
                : displayPointsInMarker
                ? isPointsExplorer
                  ? pointsRangeLabel
                  : markerPointsOrPrice
                : displayPriceInMarker
                ? markerPrice
                : undefined;

            const isActive = activeProperty === ctyhocn || selectedCtyhocn === ctyhocn;
            const suppressBrandLogo = isAffiliateHotel(display);
            const icon =
              isActive && !isNotBookable && !isPointsExplorer
                ? prefixPublicAssetUriWithAppPrefix('/assets/map-pin-active.png')
                : isActive && isNotBookable && !isPointsExplorer
                ? prefixPublicAssetUriWithAppPrefix('/assets/map-pin-alt-active.png')
                : isNotBookable && !isPointsExplorer
                ? prefixPublicAssetUriWithAppPrefix('/assets/map-pin-alt.png')
                : prefixPublicAssetUriWithAppPrefix('/assets/map-pin.png');

            return (
              <MapMarker
                isActive={isActive}
                color={
                  !!isPointsExplorer ||
                  (!!showPointsInMarker &&
                    hashedMPAData?.[ctyhocn]?.summary?.hhonors?.dailyRmPointsRateFmt)
                    ? MAP_MARKER_COLOR['POINTS_EXPLORER']
                    : undefined
                }
                icon={icon}
                id={ctyhocn}
                key={`map-marker-item-${ctyhocn}`}
                label={markerLabel || undefined}
                onClick={() => handleMapMarkerClicked(ctyhocn, name)}
                onMouseOut={() => dispatch({ type: 'UNSET_HIGHLIGHTED_MAP_CTYHOCN' })}
                onMouseOver={() =>
                  dispatch({ type: 'SET_HIGHLIGHTED_MAP_CTYHOCN', payload: ctyhocn })
                }
                position={position}
              >
                <MapInfoWindow
                  onCloseClick={() => dispatch({ type: 'SET_PDP_CTYHOCN', payload: null })}
                  position={position}
                >
                  <InfoWindow
                    address={address ? `${address.addressLine1}, ${address.city}` : ''}
                    brandCode={suppressBrandLogo ? undefined : brandCode || 'HI'}
                    ctyhocn={ctyhocn}
                    hotelBadges={<HotelBadges badges={badges} />}
                    name={name || ''}
                    containerClassName='w-56 sm:w-fit !font-["Hilton_Sans"]'
                  />
                </MapInfoWindow>
              </MapMarker>
            );
          }
        )}
    </MapMarkers>
  );
};

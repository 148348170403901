import { keepPreviousData, type UseQueryOptions } from '@tanstack/react-query';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import type { HotelSummaryOptionsQuery, HotelQuadrant } from '@dx-ui/queries-dx-shop-search-ui';
import { HotelSummaryOptionsDocument } from '@dx-ui/queries-dx-shop-search-ui';

export const generateHotelSummaryOptionsQueryArray = ({
  language,
  enabled,
  quadNodes,
  guestLocationCountry,
}: {
  language: string;
  enabled: boolean;
  quadNodes?: HotelQuadrant[];
  guestLocationCountry?: string;
}) =>
  quadNodes?.reduce((arr, node) => {
    arr.push({
      queryKey: [
        HotelSummaryOptionsDocument,
        {
          language,
          input: {
            quadrantId: node?.id,
            guestLocationCountry,
          },
        },
      ],
      enabled: isBrowser && enabled,
      placeholderData: keepPreviousData,
      retry: 2,
    });
    return arr;
  }, [] as UseQueryOptions<HotelSummaryOptionsQuery>[]) || [];

import { useQueryClient } from '@tanstack/react-query';
import type { GeocodeAddress, GeocodePlaceType } from '@dx-ui/queries-dx-shop-search-ui';
import { getHashedHotelSummaryOptionsDataFromCache } from './utils/get-hashed-hotel-summary-options-data-from-cache';

import {
  filterStateCountryBrandHashedHotels,
  filterRegionHashedHotels,
} from './utils/filter-state-country-brand-hashed-hotels';
import * as React from 'react';
import { useGetRegions } from '../use-get-regions';
import { useQuadNodes } from './use-quad-nodes';
import { useBlob } from './use-blob';
import type { HotelType } from '../../providers/app-provider/app-provider.types';

const useHotelSummaryOptions = ({
  initialHashedHotelSummaryOptionsData,
  bounds,
  brandCode,
  language,
  address,
  type,
  isInitialRender,
  matchName,
  blobEnabled = false,
  hotelSummaryExtractUrl,
}: {
  initialHashedHotelSummaryOptionsData: Record<string, HotelType>;
  bounds: google.maps.LatLngBoundsLiteral;
  brandCode?: string;
  language: string;
  address: Pick<GeocodeAddress, '_id' | 'city' | 'country' | 'state'> | null | undefined;
  type: GeocodePlaceType | undefined;
  isInitialRender: React.MutableRefObject<boolean>;
  matchName?: string;
  blobEnabled?: boolean;
  hotelSummaryExtractUrl?: string;
}) => {
  const isRegions = type === 'region';
  const { countryCodes: countriesInRegion } = useGetRegions({
    isTypeRegion: isRegions,
    regionName: matchName || '',
    isEnabled: isRegions,
  });
  const queryClient = useQueryClient();

  const stateOrCountryFilter =
    address && !isRegions
      ? {
          countries: ['country', 'state'].includes(type || '')
            ? [{ countryCode: address?.country || '', stateCode: address?.state || '' }]
            : null,
        }
      : undefined;

  let stateOrCountryOrRegionFilter = stateOrCountryFilter;
  if (isRegions) {
    stateOrCountryOrRegionFilter = {
      countries:
        countriesInRegion?.map((country) => ({
          countryCode: country,
          stateCode: '',
        })) || null,
    };
  }
  const isBlobEnabledWithExtractURL = !!hotelSummaryExtractUrl && blobEnabled;
  const {
    data: blobData,
    isFetching: isFetchingBlobNodes,
    isInitialRender: isInitialRenderBlobNodes,
    status: blobStatus,
  } = useBlob({
    enabled: isBlobEnabledWithExtractURL,
    hotelSummaryExtractUrl: hotelSummaryExtractUrl ?? '',
  });

  const isFallbackToNodes = !isBlobEnabledWithExtractURL || blobStatus === 'error';
  const { isFetching: isFetchingQuadNodes, isInitialRender: isInitialRenderQuadNodes } =
    useQuadNodes({
      language,
      enabled: isFallbackToNodes,
      bounds,
      brandCode,
      stateOrCountryOrRegionFilter,
    });

  const { isFetching, isInitial } = !isFallbackToNodes
    ? { isFetching: isFetchingBlobNodes, isInitial: isInitialRenderBlobNodes }
    : { isFetching: isFetchingQuadNodes, isInitial: isInitialRenderQuadNodes };

  if (isInitial) isInitialRender.current = false;

  const hotelDataToUse =
    !isFallbackToNodes && blobData
      ? blobData
      : getHashedHotelSummaryOptionsDataFromCache(
          queryClient,
          initialHashedHotelSummaryOptionsData
        );

  const filteredHotelDataToUse = React.useMemo(() => {
    if (isFetching) return {};
    return isRegions && countriesInRegion
      ? filterRegionHashedHotels(countriesInRegion, hotelDataToUse)
      : filterStateCountryBrandHashedHotels(address, brandCode, hotelDataToUse);
  }, [address, brandCode, countriesInRegion, hotelDataToUse, isFetching, isRegions]);

  return {
    hashedHotelSummaryOptionsHotelData: filteredHotelDataToUse,
    isFetching,
  };
};

export default useHotelSummaryOptions;

import Icon from '@dx-ui/osc-icon';
import { useHotelQuery } from '@dx-ui/queries-dx-shop-search-ui';
import { useTranslation } from 'next-i18next';
import type { DISTANCE_UNITS } from './constants/distance-units';
import type { HotelDistanceUnit, Maybe } from '@dx-ui/gql-types';
import { WhyPropertySkeleton } from './why-property-skeleton';

export const WhyProperty = ({
  ctyhocn,
  variants,
}: {
  ctyhocn: string;
  variants: Record<string, boolean>;
}) => {
  const { t } = useTranslation('hotel-card');
  /** TODO IMPLEMENTATION - using hotel query call similar to HDP for MVT b/c
   * serviceAmenities is not exposed on HSO query. If variant c is a winner,
   * we can exposed serviceAmenities on HSO and pass to this feature to avoid
   * making extra network calls and shimmer.
   */
  const { data, isLoading } = useHotelQuery({ ctyhocn, language: 'en' });

  if (isLoading) return <WhyPropertySkeleton variants={variants} />;

  const hotel = data?.hotel;
  const shortDesc = hotel?.facilityOverview?.shortDesc || '';
  const trimmedDesc = shortDesc.length > 140 ? `${shortDesc.slice(0, 140)}...` : shortDesc;
  const blurb = variants?.b ? trimmedDesc : null;
  const top3POI =
    variants?.c && hotel?.serviceAmenities
      ? hotel.serviceAmenities
          .sort((a, b) => {
            if (typeof a?.distance === 'number' && typeof b?.distance === 'number') {
              return a.distance - b.distance;
            }
            return 0;
          })
          ?.slice(0, 3)
      : null;

  return (
    <>
      <h4 className="py-3 text-sm font-bold">{t('whatsNearby')}</h4>
      {blurb ? <div>{blurb}</div> : null}
      {top3POI ? (
        <div className="flex flex-wrap gap-2">
          {top3POI.map((poi) => {
            const unitStringVariant:
              | (typeof DISTANCE_UNITS)[number]
              | undefined
              | Maybe<HotelDistanceUnit> =
              poi.distance && poi.distanceUnit && poi.distance > 1
                ? `${poi.distanceUnit}_other`
                : poi.distanceUnit;
            return (
              <div key={poi.name} className="flex items-center text-sm">
                <Icon name="point-of-interest" size="sm" />
                {poi.name}{' '}
                {unitStringVariant
                  ? t(`distanceUnits.${unitStringVariant}`, {
                      distance: poi.distanceFmt || '',
                    })
                  : ''}
              </div>
            );
          })}
        </div>
      ) : null}
      <h4 className="py-3 text-sm font-bold">{t('amenitiesWeProvide')}</h4>
      <div className="flex flex-wrap gap-2">
        {hotel?.amenities?.slice?.(0, 6)?.map((amenity) => (
          <div key={amenity.name} className="flex items-center text-sm">
            <Icon name="check" size="sm" /> {amenity?.name}
          </div>
        ))}
      </div>
    </>
  );
};

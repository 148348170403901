import { useBrandDetails, useWrappedRouter } from '../../../hooks';
import { useTranslation } from 'next-i18next';
import { useAppState } from '../../../providers/app-provider';
import { InfoMessageBanner } from '../../info-message-banner/info-message-banner';
import { Link } from '@dx-ui/osc-link';
import { env } from '../../../constants';
import { getInfoBannerURLWithBrandRemoved } from '../list-view-utils/list-view-brand-banner.utils';

export type ListViewBrandBannerProps = {
  numBrandedHotels: number;
  brandCode: string | undefined;
  isDisplayBrandBanner?: boolean;
  isPageBrandFilterEnabled?: boolean;
  isEndOfResults?: boolean;
};

export const ListViewBrandBanner = ({
  numBrandedHotels,
  brandCode,
  isDisplayBrandBanner,
  isPageBrandFilterEnabled,
  isEndOfResults = false,
}: ListViewBrandBannerProps) => {
  const { pageType } = useAppState();
  const brandInfo = useBrandDetails({ brandCode });
  const { t } = useTranslation(['list-view-brand-banner', 'list-view']);
  const { isGlobal: isGlobalPage } = pageType;
  const {
    router: { asPath, locale },
  } = useWrappedRouter();

  const atLeastOneBrandedHotel = numBrandedHotels > 0;

  const infoBannerMessage = atLeastOneBrandedHotel
    ? t('list-view-brand-banner:endOfBrandedHotelsResults', { brandName: brandInfo.name })
    : t('list-view-brand-banner:couldNotFindHotelsInSearchArea', { brandName: brandInfo.name });

  const linkWithoutBrandSlug = pageType.isBrand ? (
    <Link
      url={getInfoBannerURLWithBrandRemoved({
        baseUrl: env.OHW_BASE_URL,
        path: asPath,
        locale,
        canonicalSlug: brandInfo.canonicalSlug,
        altSlugs: brandInfo.altSlugs,
        brandCode,
      })}
      className="px-2 text-sm font-normal"
    >
      {t('exploreOtherHotelsLink')}
    </Link>
  ) : null;

  return isDisplayBrandBanner && isPageBrandFilterEnabled && isEndOfResults
    ? ((!!brandInfo?.isHotelBrand && !!brandInfo?.hasHotels) || isGlobalPage) && (
        <InfoMessageBanner message={infoBannerMessage}>{linkWithoutBrandSlug}</InfoMessageBanner>
      )
    : null;
};

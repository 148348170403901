import type { HotelType } from '../../../providers/app-provider/app-provider.types';
import type { GeocodeAddress } from '@dx-ui/queries-dx-shop-search-ui';

export const filterStateCountryBrandHashedHotels = (
  address: Pick<GeocodeAddress, 'state' | '_id' | 'city' | 'country'> | null | undefined,
  brandCode: string | undefined,
  hashedHotelSummaryOptionsHotelData: Record<string, HotelType>
) =>
  Object.keys(hashedHotelSummaryOptionsHotelData || {})
    .filter((ctyhocn) => {
      const currentHotel = hashedHotelSummaryOptionsHotelData[ctyhocn];
      if (brandCode && currentHotel && brandCode !== currentHotel.brandCode) return false;
      if (address) {
        if (
          address?.country &&
          address?.country !== hashedHotelSummaryOptionsHotelData[ctyhocn]?.address?.country
        )
          return false;
        if (
          address?.state &&
          address?.state !== hashedHotelSummaryOptionsHotelData[ctyhocn]?.address?.state
        )
          return false;
      }

      return true;
    })
    .reduce((obj, key) => {
      const hotel = hashedHotelSummaryOptionsHotelData[key];
      if (hotel) obj[key] = hotel;
      return obj;
    }, {} as Record<string, HotelType>);

export const filterRegionHashedHotels = (
  countriesInRegion: string[],
  hashedHotelSummaryOptionsHotelData: Record<string, HotelType>
) =>
  Object.keys(hashedHotelSummaryOptionsHotelData || {})
    .filter((ctyhocn) => {
      const currentHotel = hashedHotelSummaryOptionsHotelData[ctyhocn];
      return countriesInRegion?.includes(currentHotel?.address?.country || '');
    })
    .reduce((obj, key) => {
      const hotel = hashedHotelSummaryOptionsHotelData[key];
      if (hotel) obj[key] = hotel;
      return obj;
    }, {} as Record<string, HotelType>);

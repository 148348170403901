import { MapListToggleButton } from '../map-list-toggle-button/map-list-toggle-button';
import { Spinner } from '@dx-ui/osc-spinner';
import { ZoomInForPricing } from '../zoom-in-for-pricing';
import { SearchMapMarkers } from './map.markers.search';
import { MAX_PRICE_PROPERTY_LIMIT } from '../../constants';
import { Map } from './map';
import { useAppState } from '../../providers/app-provider';
import { HotelSearchResultSummaryPanel } from '../hotel-search-result-summary/hotel-search-result-summary';
import { useConductricsSelection } from '@dx-ui/framework-conductrics';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import type { ActiveFiltersState } from '../filters/filter.constants';
import type { MutableRefObject, RefObject } from 'react';
import type { DebouncedState } from 'usehooks-ts';
import type { HotelType } from '../../providers/app-provider/app-provider.types';
import { useWrappedRouter, useMapListToggle } from '../../hooks';
import { LocationsMapMarkers } from './map.markers.locations';
// eslint-disable-next-line lodash/import-scope
import type { DebouncedFunc } from 'lodash';
import type { AmenityItem } from '../filters/suggested-filters';
import type { Maybe } from '@dx-ui/gql-types';
import { useConfigRule } from '@dx-ui/framework-config-rule-provider';

type MapProps = {
  showSpinner: boolean | undefined;
  visibleFilteredInBoundHotels: string[];
  handleBoundsChange:
    | DebouncedState<(newBounds: google.maps.LatLngBoundsLiteral) => void>
    | DebouncedFunc<(newBounds: google.maps.LatLngBoundsLiteral) => void>;
  hasUserPanned: MutableRefObject<boolean>;
  hasUserZoomed: MutableRefObject<boolean>;
  initialBounds:
    | {
        south: number;
        west: number;
        north: number;
        east: number;
      }
    | google.maps.LatLngBoundsLiteral;
  sortedHotels?: HotelType[];
  filterChange: (activeFiltersState: Partial<ActiveFiltersState>) => void;
  saleFilterCount?: number | undefined;
  showSaleFilter?: boolean;
  isFindingHotels: boolean;
  h1SearchResultsContent?: string;
  hotelCounterRef: RefObject<HTMLDivElement>;
  pageHeading1Title?: string;
  centerCoordinate: {
    lat: number;
    lng: number;
  };
  amenities: AmenityItem[] | undefined;
  matchId?: Maybe<string>;
  counterMessage?: {
    customCounterMessage: string;
    customCounterSRMessage: string;
  };
};
export const MapBlock = ({
  showSpinner,
  visibleFilteredInBoundHotels,
  handleBoundsChange,
  hasUserPanned,
  hasUserZoomed,
  initialBounds,
  sortedHotels,
  filterChange,
  saleFilterCount,
  showSaleFilter,
  isFindingHotels,
  h1SearchResultsContent,
  hotelCounterRef,
  pageHeading1Title,
  amenities,
  matchId,
  centerCoordinate,
  counterMessage,
}: MapProps) => {
  const { isListVisible, hotelsInBounds } = useAppState();
  const { isSlimView, isDesktopMapView } = useMapListToggle();
  const { t } = useTranslation('search-page');
  const {
    router: { asPath },
  } = useWrappedRouter();
  const isSearchPage = asPath.includes('/search/');
  const { taggedConfig } = useConfigRule();
  const shouldShowMap = isSearchPage ? !!taggedConfig?.config['showMap'] : true;
  const { isLoaded: isConductricsLoaded } = useConductricsSelection('');

  return (
    shouldShowMap && (
      <div
        className={cx('@container z-0 h-screen text-center max-md:w-full', {
          'max-md:invisible absolute md:sticky md:top-0': isListVisible,
          'max-sm:z-1 relative': !isListVisible,
          'relative overflow-hidden': isDesktopMapView,
        })}
        data-testid="mapContainer"
      >
        {/* Map/List toggle button */}
        {shouldShowMap && !isListVisible && (
          <HotelSearchResultSummaryPanel
            amenities={amenities}
            customCounterMessage={counterMessage?.customCounterMessage}
            customCounterSRMessage={counterMessage?.customCounterSRMessage}
            hotelCount={sortedHotels?.length ?? 0}
            isFindingHotels={isFindingHotels}
            isConductricsLoaded={isConductricsLoaded}
            matchId={matchId}
            onFilterChange={(activeFiltersState: ActiveFiltersState) =>
              filterChange(activeFiltersState)
            }
            pageHeading1Title={
              pageHeading1Title ??
              t('searchResults', {
                name: h1SearchResultsContent,
              })
            }
            saleFilter={{
              showSaleFilter: !!showSaleFilter,
              saleFilterCount: saleFilterCount ?? 0,
            }}
            visibleHotels={visibleFilteredInBoundHotels}
            showingHotelDivRef={hotelCounterRef}
          />
        )}
        <div className={cx('relative h-full overflow-hidden')}>
          <div className="absolute top-3 z-20 ltr:left-3 rtl:right-3">
            <MapListToggleButton showMapListToggleButton={!isSlimView} />
          </div>

          {showSpinner && (
            <div
              className={cx('z-1 bg-bg absolute ml-4 mt-4 p-2 rtl:mr-4', {
                'top-12': !isSlimView,
              })}
            >
              <Spinner className="text-hilton-alt" size="lg" />
            </div>
          )}
          {/* Show zoom in overlay on map if we have > 150 hotels on map */}
          {visibleFilteredInBoundHotels.length > MAX_PRICE_PROPERTY_LIMIT ? (
            <div className="flex justify-center">
              <ZoomInForPricing />
            </div>
          ) : null}
          <Map
            handleBoundsChange={handleBoundsChange}
            hasUserPanned={hasUserPanned}
            hasUserZoomed={hasUserZoomed}
            initialBounds={initialBounds}
          >
            {isSearchPage && sortedHotels ? (
              <SearchMapMarkers
                centerCoordinate={centerCoordinate}
                matchId={matchId}
                sortedHotels={sortedHotels}
              />
            ) : (
              <LocationsMapMarkers
                centerCoordinate={centerCoordinate}
                hotelsInBounds={hotelsInBounds}
                visibleHotels={visibleFilteredInBoundHotels}
              />
            )}
          </Map>
        </div>
      </div>
    )
  );
};

/* eslint-disable jsx-a11y/no-onchange */
import type * as React from 'react';
import { useMetrics, useWrappedRouter } from '../../hooks';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import {
  CurrencyConverter as OSCCurrencyConverter,
  convert,
  useCurrencyConversion,
} from '@dx-ui/osc-currency-converter';
import { useFilterState } from '../../providers/filter-provider';
import { sendReward } from '@dx-ui/framework-conductrics';
import { GOALS } from '../../constants';

type CurrencyConverterProps = {
  onChange?: () => void;
};

export const CurrencyConverter = ({ onChange }: CurrencyConverterProps) => {
  const metrics = useMetrics();
  const { router } = useWrappedRouter();
  const dispatch = useAppDispatch();
  const { selectedCurrency: currencyCode, pageType, selectedCurrency } = useAppState();
  const activeFiltersState = useFilterState();

  const { currenciesMap } = useCurrencyConversion();
  async function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const currency = event.target.value;

    const fromCurrency = currenciesMap?.get(selectedCurrency || 'USD');
    const toCurrency = currenciesMap?.get(currency || 'USD');
    if (!fromCurrency || !toCurrency) {
      return;
    }
    sendReward(GOALS.currencyEngagement);
    const converted = convert(
      activeFiltersState?.priceFilter?.[1],
      fromCurrency,
      toCurrency,
      0,
      ''
    );
    dispatch({
      type: 'SET_DISPLAY_CURRENCY',
      payload: {
        currency,
        ...(activeFiltersState?.priceFilter &&
          converted.price && {
            priceFilter: [activeFiltersState?.priceFilter?.[0], converted.price],
          }),
      },
    });
    onChange?.();
    await metrics.trackCurrencyConverterClick({ currencyCode: currency || 'LOCAL' });
  }

  const selectProps = {
    id: 'selectCurrencyConverter',
    'data-testid': 'currencyConverterSelect',
  };

  if (pageType.isPointsExplorer) return null;

  return (
    <div className="w-36 items-center pl-2 text-sm md:w-64 rtl:pl-0 rtl:pr-2">
      <OSCCurrencyConverter
        showDefaultOption={true}
        language={router.locale as string}
        onChange={handleChange}
        selectedCurrency={currencyCode as string}
        hasStackedDisplay={true}
        selectProps={selectProps}
      />
    </div>
  );
};

import { useAppDispatch, useAppState } from '../../providers/app-provider';
import { useIsClient, useMediaQuery } from 'usehooks-ts';
import cx from 'classnames';
import { Icon, type IconNames } from '@dx-ui/osc-icon';
import { useMapListToggle } from '../../hooks';

export const MapListToggleButton = ({
  showMapListToggleButton = true,
  className,
}: {
  showMapListToggleButton?: boolean;
  className?: string;
}) => {
  const { isListVisible } = useAppState();
  const dispatch = useAppDispatch();
  const isClient = useIsClient();
  const isSlimView = useMediaQuery('(max-width: 767px)');

  const { iconName: listMapViewButtonIconName, label: listMapViewButtonLabel } = useMapListToggle();
  const mapToggleButtonArrowDirection = listMapViewButtonIconName as (typeof IconNames)[number];

  return isClient && showMapListToggleButton ? (
    <button
      className={cx(
        'btn flex items-center justify-center rtl:ml-0 rtl:mr-auto',
        {
          'md:hidden btn-primary': isSlimView,
          ' btn-text-outline text-base text-text px-3 py-2 shadow-xl bg-bg hover:text-text focus:text-text':
            !isSlimView,
        },
        className
      )}
      onClick={() => {
        dispatch({ type: 'SET_IS_LIST_VISIBLE', payload: !isListVisible });
      }}
      type="button"
    >
      {listMapViewButtonLabel}
      {!isSlimView ? <Icon name={mapToggleButtonArrowDirection} /> : null}
    </button>
  ) : null;
};

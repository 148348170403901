import { useTranslation } from 'next-i18next';

export const ZoomInForPricing = () => {
  const { t } = useTranslation('zoom-in-for-additional-pricing');

  return (
    <span className="z-2 border-text-alt bg-text/75 text-text-inverse @[400px]:w-auto @[460px]:mt-3 absolute mt-4 w-28 text-wrap rounded-lg border px-2 py-0.5 text-center text-sm max-2xl:top-12">
      {t('zoomInForAdditionalPricing')}
    </span>
  );
};

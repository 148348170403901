import Icon, { AmenityIcons } from '@dx-ui/osc-icon';
import type { HotelAmenityId } from '@dx-ui/queries-dx-shop-search-ui';

type HotelAmenities = { name: string; id: string };

const KEY_AMENITIES = {
  freeParking: { id: 'freeParking', name: 'Free parking' },
  freeBreakfast: { id: 'freeBreakfast', name: 'Free breakfast' },
  indoorPool: { id: 'indoorPool', name: 'Indoor pool' },
  outdoorPool: { id: 'outdoorPool', name: 'Outdoor pool' },
} as unknown as Record<string, HotelAmenities>;

export const KeyHotelAmenities = ({ amenities }: { amenities: HotelAmenityId[] | undefined }) => {
  const keyAmenities: HotelAmenities[] = [];
  amenities?.forEach((amenityId) => {
    if (Object.keys(KEY_AMENITIES).includes(amenityId)) {
      keyAmenities.push(KEY_AMENITIES[amenityId] as HotelAmenities);
    }
  });

  return keyAmenities?.length ? (
    <div className="flex flex-wrap gap-1.5 py-2.5 pb-1.5" data-testid="key-hotel-amenities">
      {keyAmenities.map((amenity: HotelAmenities) => (
        <div key={amenity.id} className="flex items-center gap-0.5 text-sm">
          <Icon name={AmenityIcons[amenity.id as HotelAmenityId]} size="sm" />
          <span> {amenity?.name}</span>
        </div>
      ))}
    </div>
  ) : null;
};

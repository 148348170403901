import { useAuth } from '@dx-ui/framework-auth-provider';
import { DISPLAY_NUM_OF_AVAILABLE_POINTS, GOALS } from '../../../constants';
import { useGetMVTSelections, useWrappedRouter } from '../../../hooks';
import { useAppState } from '../../../providers/app-provider';
import { Trans, useTranslation } from 'next-i18next';

import { useState } from 'react';
import { LoginModal } from '../../login-modal/login-modal';
import { PointsMessage } from './points-message';
import { logError } from '@dx-ui/framework-logger';
import { sendReward } from '@dx-ui/framework-conductrics';

export const DisplayPoints = () => {
  const { t } = useTranslation('use-points');
  const { shouldUsePoints } = useAppState();
  const { isAuthenticated } = useAuth();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { router } = useWrappedRouter();

  const {
    isLoaded,
    isMVTVariant: { b: isMVT },
  } = useGetMVTSelections({
    agentId: DISPLAY_NUM_OF_AVAILABLE_POINTS.agentId,
    isProvisional: shouldUsePoints,
  });

  const onLogin = () => {
    const params = new URLSearchParams(window.location.search);
    params.set('redeemPts', 'true');
    params.set('pointsLogin', 'true');
    router
      .replace(
        {
          pathname: router.pathname,
          query: params.toString(),
        },
        undefined,
        { shallow: true }
      )
      .catch((error) => logError('DISPLAY_POINTS', error, 'Error redirecting to login page'));
  };

  const handleSignInButtonClick = () => {
    setShowLoginModal(true);
    sendReward(GOALS.pointsLoginClick);
  };

  const buttonLabel = t('SRSignInToBookWithPoints');
  if (!isMVT || !isLoaded) return null;
  return isAuthenticated ? (
    <PointsMessage />
  ) : (
    <>
      <div className="text-tier-diamond text-nowrap text-sm">
        <Trans
          ns="use-points"
          i18nKey="signInToBookWithPoints"
          components={{
            SignInLink: (
              <button
                onClick={handleSignInButtonClick}
                type="button"
                className="font-normal underline"
                aria-label={buttonLabel}
                aria-live="polite"
              >
                Sign in now
              </button>
            ),
          }}
        />
      </div>
      <LoginModal
        showLoginModal={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        onLogin={onLogin}
        title={t('signInNowToPay')}
      />
    </>
  );
};

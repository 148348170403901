/* eslint-disable react/display-name */
import * as React from 'react';

import type { HotelImageRatio, Maybe } from '@dx-ui/queries-dx-shop-search-ui';
import { CarouselSingle } from '@dx-ui/osc-carousel';
import { getAspectRatioUrl } from '@dx-ui/utilities-images';
import { useRect } from '@dx-ui/utilities-use-rect';
import { env } from '../../constants';
import { useTranslation } from 'next-i18next';

export type IHotelImagesVariants =
  | {
      altText?: string | null;
      size?: 'xs' | 'sm' | 'md';
      ratios?: Maybe<Pick<HotelImageRatio, 'url' | 'size'>[]> | undefined;
    }
  | undefined;

type ICarousel = {
  images?: IHotelImagesVariants[];
  isLoading?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Carousel = ({ images, isLoading }: ICarousel) => {
  const carouselRef = React.useRef<HTMLDivElement | null>(null);
  const rect = useRect({ ref: carouselRef });

  const { t } = useTranslation(['osc-carousel']);
  const noPhotoImageUrl = `${env.HILTON_ASSETS_URI}/modules/assets/img/common/no-photo-available.png`;
  const carouselImages = images
    ?.map((props) => {
      const { imgUrl } = getAspectRatioUrl({
        src: props?.ratios?.[0]?.url,
        width: rect?.width ?? 0,
      });
      const src = props?.ratios?.[0]?.url ? imgUrl : null;
      return { alt: props?.altText || t('noPhotoAvailable'), url: src || noPhotoImageUrl };
    })
    .filter((image) => image.url);
  return carouselImages && carouselImages?.length >= 1 ? (
    <div ref={carouselRef}>
      <CarouselSingle
        data-testid="imageCarousel"
        images={carouselImages || []}
        isLoading={isLoading}
      />
    </div>
  ) : null;
};

export default Carousel;

import { useAuth } from '@dx-ui/framework-auth-provider';
import { useWrappedRouter } from '../../../hooks';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';
import cx from 'classnames';

export const PointsMessage = ({ className }: { className?: string }) => {
  const { guestInfo } = useAuth();
  const { t } = useTranslation('use-points');
  const { router } = useWrappedRouter();
  const pointsAvailable = guestInfo?.hhonors?.summary?.points;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref?.current && router.query?.['pointsLogin']) return ref.current.focus();
  }, [router.query]);

  return (
    <div className={cx('text-tier-diamond text-sm font-bold', className)} ref={ref} tabIndex={-1}>
      <span className="sr-only" aria-live="assertive">
        {t('numPoints_a11y', { numPoints: pointsAvailable })}
      </span>
      <span aria-hidden="true" className="text-nowrap">
        {t('numPoints', { numPoints: pointsAvailable })}
      </span>
    </div>
  );
};
